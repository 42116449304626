import styled from "styled-components";
import { CardValue } from "../models/gameState";

interface StyleProps {
  gray?: boolean;
  disabled?: boolean;
  asIcon?: boolean;
}

interface Props extends StyleProps {
  value: CardValue;
  index?: number;
  onClick?: (index: number) => void;
}

const StyledCard = styled.img<StyleProps>`
  width: ${({asIcon}) => asIcon ? 15.75 : 126}px;
  height: ${({asIcon}) => asIcon ? 22 : 176}px;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  transition: transform 0.1s ease-in-out;
  margin: ${({asIcon}) => asIcon ? 2: 8}px;
  filter: ${({ gray }) => (gray ? "grayscale(90%)" : "none")};
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  pointer-events: ${({ disabled }) => (disabled ? "none" : "all")};

  &:hover {
    transform: scale(1.1);
  }
`;

function Card({ value, index, disabled, onClick, gray, asIcon }: Props) {
  function clicked() {
    if (onClick && !disabled) {
      onClick(index ?? -1);
    }
  }

  return (
    <StyledCard
      src={`/img/${value}.svg`}
      onClick={clicked}
      alt={`Card with value ${value}`}
      gray={gray}
      disabled={disabled}
      asIcon={asIcon}
    />
  );
}

export default Card;
