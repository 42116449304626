import { motion } from "framer-motion";
import styled from "styled-components";
import { PlayerState } from "../models/gameState";
import Card from "./Card";
import Heading from "./Heading";
import Text from "./Text";

type Props = {
  players: Array<Pick<PlayerState, "points" | "name" | "id" | "hand">>;
  currentPlayerId: string;
  ownPlayerId: string;
};

const StyledPlayer = styled.li<{ isOwnPlayer?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  padding-top: 1rem;
`;

const PlayerStats = styled.section`
  display: flex;
  align-items: center;
`;

const List = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 1rem;
  margin: 0;
  padding: 0;
  list-style-type: none;
  margin-left: 2rem;
  width: auto;
  grid-area: players;
`;

const CardCount = styled(Text)`
  margin-right: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5ch;
  flex: 0 0 auto;
`;

const CurrentTurnIndicator = styled(motion.img)`
  height: 24px;
`;

function PlayerList({ players, currentPlayerId, ownPlayerId }: Props) {
  return (
    <List>
      {players.map((player) => (
        <StyledPlayer key={player.id} isOwnPlayer={player.id === ownPlayerId}>
          {currentPlayerId === player.id ? (
            <CurrentTurnIndicator
              animate={{ translateY: [0, -8, 8, 0] }}
              transition={{ duration: 2, repeat: Infinity }}
              src="/img/turn-indicator.svg"
              alt="It's this player's turn"
            />
          ) : null}
          <Heading as="h3">{player.name}</Heading>
          <PlayerStats>
            <CardCount>
              {player.hand.length}x <Card value={10} asIcon />
            </CardCount>
            <Text>{player.points} P</Text>
          </PlayerStats>
        </StyledPlayer>
      ))}
    </List>
  );
}

export default PlayerList;
