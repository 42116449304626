import styled from "styled-components";

const TextInput = styled.input`
  font-family: var(--font-theme);
  border: 2px solid black;
  background-color: transparent;
  font-size: 1rem;
  padding: 0.5rem 0.75rem;
  border-radius: 5px;
  height: 3rem;
  box-sizing: border-box;
  -webkit-text-fill-color: white;
  -webkit-text-stroke: 1px black;
`;

export default TextInput;
