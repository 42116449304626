import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DebugPanel from "../components/DebugPanel";
import GameBoard from "../components/GameBoard";
import GameFinishedPanel from "../components/GameFinishedPanel";
import { useRealtimeClient } from "../lib/api/realtimeClient";
import { cardPlayedEvent, drawCardEvent, passEvent } from "../models/events";
import { GameState } from "../models/gameState";

function Game() {
  const { gameId, playerId } = useParams();
  const realtimeClient = useRealtimeClient();
  const [state, setState] = useState<GameState | null>(null);
  const [ownPlayerId, setOwnPlayerId] = useState<string>(playerId!);

  useEffect(() => {
    // There are two cases where this can happen:
    //   1. We messed up in the lobby and didn't properly connect
    //   2. The connection dropped
    if (!realtimeClient.isConnected()) {
      realtimeClient.connect(gameId!, playerId!);
    }
    console.debug("[Game] Subscribing to 'game/new-state' event");
    const gameStateHandlerId = realtimeClient.subscribe(
      "game/new-state",
      (data) => {
        setState(data as GameState);
      }
    );

    return () => {
      console.debug("[Game] Unsubscribing event handlers");
      realtimeClient.unsubscribe("game/new-state", gameStateHandlerId);
    };
  }, [gameId, playerId, realtimeClient]);

  function onCardPlayed(index: number) {
    realtimeClient.send(cardPlayedEvent(ownPlayerId, index));
  }

  function onDrawCard() {
    realtimeClient.send(drawCardEvent(ownPlayerId));
  }

  function onPlayerPass() {
    realtimeClient.send(passEvent(ownPlayerId));
  }

  return (
    <div>
      <header></header>
      <div>
        {state && ownPlayerId ? (
          state.status === "playing" ? (
            <GameBoard
              state={state}
              ownPlayerId={ownPlayerId}
              onPlayCard={onCardPlayed}
              onDrawCard={onDrawCard}
              onPlayerPass={onPlayerPass}
            />
          ) : (
            <GameFinishedPanel
              players={state.players.filter((p) => p.points >= 40)}
            />
          )
        ) : (
          <div>
            Waiting for initial state...
            <br />
            If this message persists, please try reloading the page
          </div>
        )}
      </div>
      <footer>
        {process.env.NODE_ENV !== "production" ? (
          <DebugPanel d={{ state, ownPlayerId, setOwnPlayerId }} />
        ) : null}
      </footer>
    </div>
  );
}

export default Game;
