import styled from "styled-components";

interface HeadingProps {
  as: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  children?: JSX.Element | string | Array<JSX.Element | string | undefined>;
}

const StyledHeading = styled.div`
  font-family: var(--font-theme);
  -webkit-text-stroke: 1px black;
  -webkit-text-fill-color: white;
  color: white;
  font-size: 2rem;
  line-height: 1.5;
  margin-bottom: 0;
  /* text-shadow: -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000,
    2px 2px 0 #000; */
`;

function Heading({ as, children }: HeadingProps) {
  return <StyledHeading as={as}>{children}</StyledHeading>;
}

export default Heading;
