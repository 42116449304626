import { motion } from "framer-motion";
import styled from "styled-components";
import { GameState } from "../models/gameState";
import Card from "./Card";

interface Props {
  stack: GameState["stack"];
}

const Stack = styled.div`
  grid-area: stack;
  position: relative;
`;

const StackContainer = styled(motion.div)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform-origin: center;
`;

function CardStack({ stack }: Props) {
  console.log(stack);
  return (
    <Stack>
      {stack.map((card, index) => (
        <StackContainer
          initial={{
            scale: 1.5,
            opacity: 0.2,
            rotate: `${card.rotation}deg`,
            translate: "-50%, -50%",
          }}
          animate={{
            scale: 1,
            opacity: 1,
            rotate: `${card.rotation}deg`,
            translate: "-50%, -50%",
          }}
          key={index}
        >
          <Card value={card.value} disabled />
        </StackContainer>
      ))}
    </Stack>
  );
}

export default CardStack;
