import { AnimatePresence } from "framer-motion";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./index.css";
import {
  RealtimeClient,
  RealtimeClientProvider,
} from "./lib/api/realtimeClient";
import reportWebVitals from "./reportWebVitals";
import Game from "./routes/Game";
import Home from "./routes/Home";
import Lobby from "./routes/Lobby";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const realtimeClient = new RealtimeClient();

root.render(
  <React.StrictMode>
    <RealtimeClientProvider value={realtimeClient}>
      <BrowserRouter>
        <AnimatePresence>
          <Routes>
            <Route path="/" key="Home" element={<Home key="Home" />} />
            <Route
              path="lobby/:gameId"
              key="Lobby"
              element={<Lobby key="Lobby" />}
            />
            <Route
              path="game/:gameId/player/:playerId"
              key="Game"
              element={<Game key="Game" />}
            />
          </Routes>
        </AnimatePresence>
      </BrowserRouter>
    </RealtimeClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
