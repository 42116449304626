import { AnimatePresence, motion } from "framer-motion";
import styled from "styled-components";
import { CardValue } from "../models/gameState";
import Card from "./Card";

export interface HandCardsProps {
  cards: CardValue[] | undefined;
  onPlayCard: (index: number) => void;
  lastCardInStack: number;
}

const HandCardsContainer = styled.div`
  overflow-x: auto;
  display: flex;
  flex-wrap: nowrap;
  flex: 1 1 auto;
  padding-top: 40px;
  gap: 0.25rem;
  box-sizing: border-box;
  justify-content: center;
`;

const CardContainer = styled(motion.div)`
  position: absolute;
  left: 50%;
  bottom: 0;
`;

function cardIsValidAsNextCard(lastCardInStack: number, card: CardValue) {
  if (lastCardInStack === 10) {
    return card === 10 || card === 1;
  }
  if (card < lastCardInStack) {
    return false;
  }
  if (lastCardInStack === 6) {
    return card === 6 || card === 10;
  }
  return card < lastCardInStack + 2;
}

function cardRotation(total: number, index: number): number {
  const stepSize = 10;
  return (total / 2) * -stepSize + (index + 0.5) * stepSize;
}

function translationX(total: number, index: number): number {
  const stepSize = 80;

  return index * stepSize - (total / 2) * stepSize;
}

function translationY(total: number, index: number): number {
  const stepSize = 5;
  return (total / 2 - index) * (total / 2 - index - 0.5) * stepSize + 20;
}

function HandCards({ cards, onPlayCard, lastCardInStack }: HandCardsProps) {
  const numCards = cards?.length || 0;
  return (
    <HandCardsContainer>
      <AnimatePresence>
        {cards?.map((card, i) => (
          <CardContainer
            initial={{
              translateY: 100,
              opacity: 0.1,
              rotate: cardRotation(numCards, i),
              translateX: translationX(numCards, i),
            }}
            animate={{
              translateY: translationY(numCards, i),
              opacity: 1,
              rotate: cardRotation(numCards, i),
              translateX: translationX(numCards, i),
            }}
            whileHover={{ scale: 1.2 }}
            exit={{ translateY: -50, opacity: 0 }}
            key={i}
          >
            <Card
              value={card}
              index={i}
              onClick={onPlayCard}
              key={i}
              disabled={!cardIsValidAsNextCard(lastCardInStack, card)}
              gray={!cardIsValidAsNextCard(lastCardInStack, card)}
            />
          </CardContainer>
        ))}
      </AnimatePresence>
    </HandCardsContainer>
  );
}

export default HandCards;
