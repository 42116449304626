function createEvent(name: string, data: object) {
  console.debug("Created", name, "event:", data);
  return {
    event: name,
    data: { ...data },
  };
}

export function drawCardEvent(playerId: string) {
  return createEvent("client/draw-card", { playerId });
}

export function cardPlayedEvent(playerId: string, cardIndex: number) {
  return createEvent("client/card-played", { playerId, cardIndex });
}

export function passEvent(playerId: string) {
  return createEvent("client/pass-round", { playerId });
}
