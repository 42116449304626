import styled from "styled-components";

export interface TextProps {
  size?: "default" | "small";
}

const Text = styled.span<TextProps>`
  font-family: var(--font-theme);
  -webkit-text-stroke: 1px black;
  -webkit-text-fill-color: white;
  color: white;
  font-size: ${(props) => (props.size === "small" ? 1 : 1.25)}rem;
  line-height: 1.5;
`;

export default Text;
