import styled from "styled-components";

const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  flex: 0 0 auto;
`;

const Logo = styled.img`
  max-height: 4rem;
  aspect-ratio: 1;
  margin-right: 1rem;
`;

const Title = styled.h1`
  -webkit-text-stroke: 1px black;
  -webkit-text-fill-color: white;
  font-family: var(--font-theme);
  text-shadow: -4px 4px 12px rgba(0, 0, 0, 0.05);
  margin: 0;
  padding: 0;
  font-size: 4rem;
`;

export default function Header() {
  return (
    <StyledHeader>
      <Logo src="/img/Alpaca 3D.svg" />
      <Title>Alpacas</Title>
    </StyledHeader>
  );
}
