import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import Button from "../components/Button";
import { DefaultPageContainer } from "../components/DefaultPageContainer";
import Header from "../components/Header";
import Heading from "../components/Heading";
import Text from "../components/Text";
import TextInput from "../components/TextInput";
import { joinGame } from "../lib/api/joinGame";
import { usePlayersInGame } from "../lib/api/playersInGame";
import { useRealtimeClient } from "../lib/api/realtimeClient";
import { startGame } from "../lib/api/startGame";
import { updateName } from "../lib/api/updateName";
import { GameState } from "../models/gameState";

const Main = styled.main`
  max-width: 1000px;
  width: 90vw;
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.5rem 1.5rem;
  gap: 0.5rem;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 15px;
  box-shadow: -4px 4px 15px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
`;

const NameSection = styled.section`
  padding-top: 0.75rem;
`;

function Lobby() {
  const { gameId } = useParams();
  const {
    data: players,
    error,
    update: updatePlayersInGame,
  } = usePlayersInGame(gameId!, true);
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [joiningGame, setJoiningGame] = useState(false);
  const [playerId, setPlayerId] = useState<string | undefined>();
  const realtimeClient = useRealtimeClient();

  async function onJoinGame() {
    if (name.length < 1) {
      console.error("[Lobby] Name wasn't given when trying to join game");
      return;
    }
    console.info("[Lobby] Joining game as", name);
    setJoiningGame(true);
    const playerId = await joinGame(gameId!, name);
    console.info("[Lobby] Got assigned player ID", playerId);
    realtimeClient.connect(gameId!, playerId);
    setPlayerId(playerId);
  }

  async function onChangeName() {
    if (name.length < 1) {
      console.error("[Lobby] Tried to change name, but it was empty");
      return;
    }
    console.info("[Lobby] Updating name to be", name);
    await updateName(gameId!, playerId!, name);
  }

  useEffect(() => {
    console.debug("[Lobby] Subscribing to 'game/started' event");
    const gameStartedHandler = realtimeClient.subscribe(
      "game/started",
      onGameStarted
    );
    console.debug("[Lobby] Subscribing to 'game/new-state' event");
    const newGameStateHandler = realtimeClient.subscribe(
      "game/new-state",
      onGameStateUpdated
    );
    return () => {
      console.debug("[Lobby] Unsubscribing event handlers");
      realtimeClient.unsubscribe("game/start", gameStartedHandler);
      realtimeClient.unsubscribe("game/new-state", newGameStateHandler);
    };
    // TODO: Investigate useEffect dependencies
  }, [realtimeClient, gameId, playerId, navigate]);

  const onGameStarted = () => {
    console.log("[Lobby] Game has been started, navigating to game screen");
    navigate(`/game/${gameId}/player/${playerId}`);
  };

  const onGameStateUpdated = (state: GameState) => {
    console.log("[Lobby] New game state received, refetching player list");
    updatePlayersInGame();
  };

  // TODO: We need to navigate all other clients to the main game page as well. Should we maybe use websockets here instead of polling?
  async function onStartGame() {
    await startGame(gameId!);
  }

  const gameLink = `${window.location.toString()}`;

  const listFormatter = new Intl.ListFormat("en-US", { type: "conjunction" });

  return (
    <DefaultPageContainer>
      <Header />
      <Main>
        <MainContainer>
          <Heading as="h2">
            You're joining the Lobby &quot;{gameId}&quot;
          </Heading>
          <Text>
            {players?.length === 0
              ? "No one else is here yet... It’s a bit lonely, don’t you think?"
              : listFormatter.format(players?.map((p) => p.name) || []) +
                ` ${(players?.length || 0) > 1 ? "are" : "is"} already there`}
          </Text>
          <Text size="small">
            Invite more friends by sending them this link: {gameLink}
          </Text>

          <NameSection>
            <TextInput
              type="text"
              min={1}
              maxLength={30}
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter your name"
            />
            {playerId ? (
              <Button
                onClick={onStartGame}
                disabled={name.length < 1}
                variant="ghost"
              >
                Start Game!
              </Button>
            ) : (
              <Button
                onClick={onJoinGame}
                disabled={name.length < 1 || joiningGame}
                variant="ghost"
              >
                Join -&gt;
              </Button>
            )}
          </NameSection>
        </MainContainer>
      </Main>
    </DefaultPageContainer>
  );
}

export default Lobby;
