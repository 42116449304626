import { Link } from "react-router-dom";
import styled from "styled-components";
import { PlayerState } from "../models/gameState";
import Button from "./Button";
import Heading from "./Heading";
import Text from "./Text";

export interface GameFinishedPanelProps {
  players: PlayerState[];
}

const Main = styled.main`
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.5rem 1.5rem;
  gap: 0.5rem;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 15px;
  box-shadow: -4px 4px 15px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
`;

function GameFinishedPanel({ players }: GameFinishedPanelProps) {
  const formatter = new Intl.ListFormat("en-US", { type: "conjunction" });
  const playerList = formatter.format(
    players.map((player) => `${player.name} (${player.points})`)
  );
  return (
    <Main>
      <MainContainer>
        <Heading as="h3">Game over!</Heading>
        <Text>{`${playerList} had 40 or more points`}</Text>
        <Link to="/">
          <Button>Go back home</Button>
        </Link>
      </MainContainer>
    </Main>
  );
}

export default GameFinishedPanel;
