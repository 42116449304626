import { createContext, useContext } from "react";

export type SocketMessage = {
  event: string;
  data: any;
};

export class RealtimeClient {
  private playerId: string | undefined;
  private socket: WebSocket | undefined;
  private handlers: Map<string, Array<(data: any) => any>> = new Map();
  private sendQueue: Array<any> = [];

  // TODO: Implement automatic reconnects
  public async connect(gameId: string, playerId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      // TODO: Should we accept this as a parameter?
      const { host } = window.location;
      // TODO: Implement switching between ws and wss for development vs. production
      const proto = (process.env.NODE_ENV === 'production') ? 'wss' : 'ws';
      this.socket = new WebSocket(
        `${proto}://${host}/api/v1/games/${gameId}/players/${playerId}/ws`
      );
      this.socket.addEventListener("open", () => {
        console.debug("[RealtimeClient] Socket connection established");
        if (this.sendQueue.length === 0) {
          resolve();
        } else {
          for (let message of this.sendQueue) {
            this.sendMessage(message);
          }
          this.sendQueue = [];
          resolve();
        }
      });
      this.socket.addEventListener("close", () => {
        console.debug("[RealtimeClient] Socket connection closed");
      });
      this.socket.addEventListener("message", (msg) => this.handleMessage(msg));
    });
  }

  public disconnect() {
    this.socket?.close();
    this.socket = undefined;
  }

  public isConnected() {
    return this.socket?.readyState === WebSocket.OPEN;
  }

  private handleMessage(message: MessageEvent) {
    const { event, data } = JSON.parse(message.data);
    console.debug("[RealtimeClient] Received websocket event", event);
    if (this.handlers.has(event)) {
      // We know that this.handlers.get() cannot be undefined here because of the check above
      for (const handler of this.handlers.get(event)!) {
        handler(data);
      }
    }
  }

  public subscribe(event: string, handler: (data: any) => any): number {
    if (!this.handlers.has(event)) {
      this.handlers.set(event, [handler]);
      return 0;
    }
    this.handlers.get(event)?.push(handler);
    return this.handlers.get(event)?.length ?? 0 - 1;
  }

  public unsubscribe(event: string, handlerIndex: number) {
    if (!this.handlers.has(event)) {
      return;
    }
    this.handlers.get(event)?.splice(handlerIndex, 1);
  }

  private sendMessage(message: SocketMessage) {
    this.socket?.send(JSON.stringify(message));
  }

  public send(message: SocketMessage) {
    if (this.isConnected()) {
      this.sendMessage(message);
    } else {
      console.warn(
        "[RealtimeClient] Sending message before socket is connected. Pushing to send queue"
      );
      this.sendQueue.push(message);
    }
  }
}

const RealtimeClientContext = createContext<RealtimeClient>(
  new RealtimeClient()
);
const RealtimeClientProvider = RealtimeClientContext.Provider;
const useRealtimeClient = () => useContext(RealtimeClientContext);
export { RealtimeClientProvider, useRealtimeClient };
