import { Link } from "react-router-dom";
import styled from "styled-components";
import { useLobbies } from "../lib/api/lobbies";
import Text from "./Text";

type Lobby = {
  id: string;
  playerCount: number;
  capacity: number;
};

const LobbyLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  text-decoration: none;
  gap: 0.5rem;
`;

const List = styled.ul`
  list-style-type: none;
  padding: 0;
  width: 100%;
`;

const LobbyName = styled(Text)`
  flex: 1 1 auto;
  text-align: left;
`;

function LobbyList() {
  const { data: lobbies, error } = useLobbies();
  if (error) {
    return <span>Failed to load active lobbies. Please try again</span>;
  }

  if (!lobbies) {
    return <span>Loading active lobbies, please wait 🦙</span>;
  }

  return (
    <List>
      {lobbies.map((lobby: Lobby) => (
        <li key={lobby.id}>
          <LobbyLink to={`/lobby/${lobby.id}`}>
            <LobbyName>{lobby.id}</LobbyName>
            <Text>
              {lobby.playerCount}/{lobby.capacity}
            </Text>
            <Text>Join -&gt;</Text>
          </LobbyLink>
        </li>
      ))}
    </List>
  );
}

export default LobbyList;
