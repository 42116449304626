import useSWR, { useSWRConfig } from "swr";
import { jsonFetcher } from "./jsonFetcher";

type PlayerDto = {
  id: string;
  name: string;
};

const swrOptions = (shouldAutoRefresh?: boolean) =>
  shouldAutoRefresh ? { refreshInterval: 500 } : {};

const endpoint = (gameId: string) => `/api/v1/games/${gameId}/players`;

export const usePlayersInGame = (
  gameId: string,
  shouldAutoRefresh?: boolean
) => ({
  ...useSWR<PlayerDto[]>(
    endpoint(gameId),
    jsonFetcher,
    swrOptions(shouldAutoRefresh)
  ),
  update: useSWRConfig().mutate.bind(this, endpoint(gameId)),
});
