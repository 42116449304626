import styled from "styled-components";
import { GameState } from "../models/gameState";
import Button from "./Button";
import CardStack from "./CardStack";
import HandCards from "./HandCards";
import PlayerList from "./PlayerList";

interface Props {
  state: GameState;
  ownPlayerId: string;
  onPlayCard: (index: number) => void;
  onDrawCard: () => void;
  onPlayerPass: () => void;
}

const StyledBoard = styled.div`
  width: 100vw;
  height: 100vh;
  display: grid;
  grid:
    "players" max-content
    "stack" 1fr
    "hand" max-content
    / 1fr;
  background-color: brown;
  background-image: url("/img/alpacas-bg.svg");
  background-position: 100% 100%;
  background-size: cover;
  overflow: hidden;
`;

const Hand = styled.div`
  grid-area: hand;
  display: flex;
  align-items: flex-end;
  overflow: hidden;
  padding: 1rem 1.5rem 0 1.5rem;
`;

function GameBoard({
  state,
  ownPlayerId,
  onPlayCard,
  onDrawCard,
  onPlayerPass,
}: Props) {
  const lastCardInStack: number =
    state.stack.length === 0 ? 0 : state.stack[state.stack.length - 1].value;

  const playersStillInRound = state.players.filter(
    (player) => !player.hasPassedRound
  );

  return (
    <StyledBoard>
      <PlayerList
        players={state.players}
        currentPlayerId={state.currentPlayerId}
        ownPlayerId={ownPlayerId}
      />
      <CardStack stack={state.stack} />
      <Hand>
        <Button size="lg" onClick={onPlayerPass} variant="ghost">
          X Pass Round
        </Button>
        <HandCards
          cards={state.players.find((p) => p.id === ownPlayerId)?.hand}
          onPlayCard={onPlayCard}
          lastCardInStack={lastCardInStack}
        />
        <Button
          size="lg"
          onClick={onDrawCard}
          disabled={playersStillInRound.length < 2}
          variant="ghost"
        >
          +1 Draw Card
        </Button>
      </Hand>
    </StyledBoard>
  );
}

export default GameBoard;
