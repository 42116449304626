import { GameState } from "../../models/gameState";

export async function startGame(gameId: string) {
  const newGameStatus: GameState["status"] = "playing";
  const response = await fetch(`/api/v1/games/${gameId}`, {
    method: "PATCH",
    body: JSON.stringify({ status: newGameStatus }),
  });
  if (response.status < 200 || response.status >= 400) {
    throw new Error(
      `Unexpected response status ${response.status} while starting game`
    );
  }
}
