import styled from "styled-components";

type Sizes = "md" | "lg";

interface Props {
  variant?: "default" | "ghost";
  size?: Sizes;
}

type SizedValues = {
  [size in Sizes]: any;
};

function sized(values: SizedValues): (props: Props) => any {
  return function (props: Props) {
    return values[props.size || "md"];
  };
}

function isGhost(yes: any, no: any): (props: Props) => any {
  return function (props: Props) {
    return props.variant === "ghost" ? yes : no;
  };
}

const Button = styled.button<Props>`
  appearance: none;
  background: transparent;
  -webkit-text-stroke: 1px black;
  -webkit-text-fill-color: white;
  /* background: white; */
  border: ${isGhost("none", "2px solid black")};
  padding: ${sized({ md: "0.5rem 2rem", lg: "0.75rem 1.5rem" })};
  font-size: ${sized({ md: 1, lg: 2 })}rem;
  font-family: var(--font-theme);
  color: rgba(0, 0, 0, 0.8);
  border-radius: 5px;
  cursor: pointer;
  height: ${sized({ md: 3, lg: 4 })}rem;
  min-width: ${isGhost("auto", "15ch")};
  white-space: pre;

  &:disabled {
    cursor: not-allowed;
    -webkit-text-stroke-color: rgba(0, 0, 0, 0.3);
    -webkit-text-fill-color: rgba(255, 255, 255, 0.1);
    color: rgba(0, 0, 0, 0.55);
  }
`;

export default Button;
