export async function joinGame(gameId: string, name: string): Promise<string> {
  const response = await fetch(`/api/v1/games/${gameId}/players`, {
    method: "POST",
    body: JSON.stringify({ name }),
  });
  if (response.status < 200 || response.status >= 400) {
    throw new Error(
      `Unexpected response status ${response.status} when joining game`
    );
  }
  const data = await response.json();
  return data.playerId;
}
