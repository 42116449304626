import { motion } from "framer-motion";
import styled from "styled-components";

export const DefaultPageContainer = styled(motion.div).attrs({
  initial: { opacity: 0.5, translateX: 500 },
  animate: { opacity: 1, translateX: 0 },
  exit: { opacity: 0.5, translateX: -500 },
})`
  height: 100vh;
  width: 100vw;
  color: white;
  margin: 0;
  padding: 0 2rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
`;
