import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Button from "../components/Button";
import { DefaultPageContainer } from "../components/DefaultPageContainer";
import Header from "../components/Header";
import Heading from "../components/Heading";
import LobbyList from "../components/LobbyList";

const Main = styled.main`
  max-width: 1000px;
  width: 90vw;
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MainContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 1.5rem;
  gap: 2.5rem;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 15px;
  box-shadow: -4px 4px 15px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
`;

const JoinGamePanel = styled.section`
  text-align: center;
  padding: 0 1rem;
`;

const StartNewGamePanel = styled.section`
  text-align: center;
  padding: 0 1rem;
`;

const PanelDivider = styled.div`
  width: 2px;
  background: #000;
  height: 75px;
`;

function Home() {
  const [creatingNewGame, setCreatingNewGame] = useState(false);
  const navigate = useNavigate();

  async function onCreateNewGame() {
    setCreatingNewGame(true);
    try {
      const response = await fetch("/api/v1/games", {
        method: "POST",
      });
      const data = await response.json();
      const { gameId } = data;
      navigate(`/lobby/${gameId}`);
    } catch (ex) {
      console.error("Failed to create new lobby!", ex);
      setCreatingNewGame(false);
    }
  }

  return (
    <DefaultPageContainer>
      <Header />
      <Main>
        <MainContainer>
          <JoinGamePanel>
            <Heading as="h2">Join an open Lobby</Heading>
            <LobbyList />
          </JoinGamePanel>
          <PanelDivider />
          <StartNewGamePanel>
            <Heading as="h2">Or start a new Game</Heading>
            <Button disabled={creatingNewGame} onClick={onCreateNewGame}>
              Play now!
            </Button>
          </StartNewGamePanel>
        </MainContainer>
      </Main>
    </DefaultPageContainer>
  );
}

export default Home;
